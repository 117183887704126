import React, { useRef, useEffect } from 'react';

const BoidSimulation = () => {
    const canvasRef = useRef(null);
    let mouse = { x: null, y: null };
    const numBoids = 100;
    const visionRadius = 50;
    const maxSpeed = 2;
    const maxForce = 0.013;

    let boids = []; // Move boids array outside the useEffect to maintain state on resize
    let adversary;  // Similarly for the adversary

    // Boid class definition
    class Boid {
        constructor(x, y) {
            this.position = { x: x, y: y };
            this.velocity = { x: Math.random() * 2 - 1, y: Math.random() * 2 - 1 };
            this.acceleration = { x: 0, y: 0 };
            this.target = { x: Math.random() * canvasRef.current.width, y: Math.random() * canvasRef.current.height };
        }

        applyBehaviors(boids, adversary) {
            let alignment = this.align(boids);
            let cohesion = this.cohesion(boids);
            let separation = this.separation(boids);
            let seek = this.seek();
            let fleeAdversary = this.flee(adversary);
            this.acceleration.x += alignment.x + cohesion.x + separation.x + seek.x + fleeAdversary.x;
            this.acceleration.y += alignment.y + cohesion.y + separation.y + seek.y + fleeAdversary.y;
        }

        align(boids) {
            let steering = { x: 0, y: 0 };
            let total = 0;
            for (let other of boids) {
                let distance = Math.hypot(this.position.x - other.position.x, this.position.y - other.position.y);
                if (other !== this && distance < visionRadius) {
                    steering.x += other.velocity.x;
                    steering.y += other.velocity.y;
                    total++;
                }
            }
            if (total > 0) {
                steering.x /= total;
                steering.y /= total;
                steering.x -= this.velocity.x;
                steering.y -= this.velocity.y;
                let magnitude = Math.sqrt(steering.x ** 2 + steering.y ** 2);
                if (magnitude > maxForce) {
                    steering.x = (steering.x / magnitude) * maxForce;
                    steering.y = (steering.y / magnitude) * maxForce;
                }
            }
            return steering;
        }

        cohesion(boids) {
            let perceptionRadius = visionRadius;
            let steering = { x: 0, y: 0 };
            let total = 0;
            for (let other of boids) {
                let d = Math.hypot(other.position.x - this.position.x, other.position.y - this.position.y);
                if (other !== this && d < perceptionRadius) {
                    steering.x += other.position.x;
                    steering.y += other.position.y;
                    total++;
                }
            }
            if (total > 0) {
                steering.x /= total;
                steering.y /= total;
                steering.x -= this.position.x;
                steering.y -= this.position.y;
                let magnitude = Math.sqrt(steering.x ** 2 + steering.y ** 2);
                if (magnitude > 0) {
                    steering.x = (steering.x / magnitude) * maxSpeed - this.velocity.x;
                    steering.y = (steering.y / magnitude) * maxSpeed - this.velocity.y;
                    magnitude = Math.sqrt(steering.x ** 2 + steering.y ** 2);
                    if (magnitude > maxForce) {
                        steering.x = (steering.x / magnitude) * maxForce;
                        steering.y = (steering.y / magnitude) * maxForce;
                    }
                }
            }
            return steering;
        }

        separation(boids) {
            let perceptionRadius = visionRadius / 2;
            let steering = { x: 0, y: 0 };
            let total = 0;
            for (let other of boids) {
                let d = Math.hypot(other.position.x - this.position.x, other.position.y - this.position.y);
                if (other !== this && d < perceptionRadius) {
                    let diff = { x: this.position.x - other.position.x, y: this.position.y - other.position.y };
                    if (d > 0) {
                        steering.x += diff.x / d;
                        steering.y += diff.y / d;
                    }
                    total++;
                }
            }
            if (total > 0) {
                steering.x /= total;
                steering.y /= total;
                let magnitude = Math.sqrt(steering.x ** 2 + steering.y ** 2);
                if (magnitude > 0) {
                    steering.x = (steering.x / magnitude) * maxSpeed - this.velocity.x;
                    steering.y = (steering.y / magnitude) * maxSpeed - this.velocity.y;
                    magnitude = Math.sqrt(steering.x ** 2 + steering.y ** 2);
                    if (magnitude > maxForce) {
                        steering.x = (steering.x / magnitude) * maxForce;
                        steering.y = (steering.y / magnitude) * maxForce;
                    }
                }
            }
            return steering;
        }

        seek() {
            let desired = { x: this.target.x - this.position.x, y: this.target.y - this.position.y };
            let magnitude = Math.sqrt(desired.x ** 2 + desired.y ** 2);
            if (magnitude > 0) {
                desired.x = (desired.x / magnitude) * maxSpeed;
                desired.y = (desired.y / magnitude) * maxSpeed;
                desired.x -= this.velocity.x;
                desired.y -= this.velocity.y;
                magnitude = Math.sqrt(desired.x ** 2 + desired.y ** 2);
                if (magnitude > maxForce) {
                    desired.x = (desired.x / magnitude) * maxForce;
                    desired.y = (desired.y / magnitude) * maxForce;
                }
            }
            return desired;
        }

        flee(adversary) {
            let fleeForce = { x: 0, y: 0 };
            let fleeRadius = 100;
            let distanceToAdversary = Math.hypot(this.position.x - adversary.position.x, this.position.y - adversary.position.y);
            if (distanceToAdversary < fleeRadius) {
                let fleeDirection = {
                    x: this.position.x - adversary.position.x,
                    y: this.position.y - adversary.position.y
                };
                let magnitude = Math.sqrt(fleeDirection.x ** 2 + fleeDirection.y ** 2);
                if (magnitude > 0) {
                    fleeDirection.x = (fleeDirection.x / magnitude) * maxSpeed;
                    fleeDirection.y = (fleeDirection.y / magnitude) * maxSpeed;
                    fleeDirection.x -= this.velocity.x;
                    fleeDirection.y -= this.velocity.y;
                    magnitude = Math.sqrt(fleeDirection.x ** 2 + fleeDirection.y ** 2);
                    if (magnitude > maxForce) {
                        fleeDirection.x = (fleeDirection.x / magnitude) * maxForce;
                        fleeDirection.y = (fleeDirection.y / magnitude) * maxForce;
                    }
                    fleeForce.x = fleeDirection.x;
                    fleeForce.y = fleeDirection.y;
                }
            }
            return fleeForce;
        }

        update(canvas) {
            this.velocity.x += this.acceleration.x;
            this.velocity.y += this.acceleration.y;
            let speed = Math.sqrt(this.velocity.x ** 2 + this.velocity.y ** 2);
            if (speed > maxSpeed) {
                this.velocity.x = (this.velocity.x / speed) * maxSpeed;
                this.velocity.y = (this.velocity.y / speed) * maxSpeed;
            }
            this.position.x += this.velocity.x;
            this.position.y += this.velocity.y;
            if (this.position.x < 0) this.position.x = canvas.width;
            else if (this.position.x > canvas.width) this.position.x = 0;
            if (this.position.y < 0) this.position.y = canvas.height;
            else if (this.position.y > canvas.height) this.position.y = 0;
            this.acceleration.x = 0;
            this.acceleration.y = 0;
            let distanceToMouse = Math.hypot(this.position.x - mouse.x, this.position.y - mouse.y);
            if (distanceToMouse < 100) {
                let fleeDirection = { x: this.position.x - mouse.x, y: this.position.y - mouse.y };
                let magnitude = Math.sqrt(fleeDirection.x ** 2 + fleeDirection.y ** 2);
                fleeDirection.x = (fleeDirection.x / magnitude) * 0.5;
                fleeDirection.y = (fleeDirection.y / magnitude) * 0.5;
                this.acceleration.x += fleeDirection.x;
                this.acceleration.y += fleeDirection.y;
            }
            let distanceToTarget = Math.hypot(this.position.x - this.target.x, this.position.y - this.target.y);
            if (distanceToTarget < 5) {
                this.target = { x: Math.random() * canvas.width, y: Math.random() * canvas.height };
            }
        }

        draw(ctx) {
            ctx.beginPath();
            ctx.arc(this.position.x, this.position.y, 1, 0, Math.PI * 2);
            ctx.fillStyle = 'black';
            ctx.fill();
        }
    }

    // Adversary class definition
    class Adversary {
        constructor(x, y) {
            this.position = { x: x, y: y };
            this.velocity = { x: Math.random() * 2 - 1, y: Math.random() * 2 - 1 };
            this.acceleration = { x: 0, y: 0 };
        }

        update(boids) {
            let target = this.findClosestBoid(boids);
            if (target) {
                let desired = { x: target.position.x - this.position.x, y: target.position.y - this.position.y };
                let magnitude = Math.sqrt(desired.x ** 2 + desired.y ** 2);
                if (magnitude > 0) {
                    desired.x = (desired.x / magnitude) * maxSpeed;
                    desired.y = (desired.y / magnitude) * maxSpeed;
                    desired.x -= this.velocity.x;
                    desired.y -= this.velocity.y;
                    magnitude = Math.sqrt(desired.x ** 2 + desired.y ** 2);
                    if (magnitude > maxForce) {
                        desired.x = (desired.x / magnitude) * maxForce;
                        desired.y = (desired.y / magnitude) * maxForce;
                    }
                    this.acceleration.x = desired.x;
                    this.acceleration.y = desired.y;
                }
            }
            this.velocity.x += this.acceleration.x;
            this.velocity.y += this.acceleration.y;
            let speed = Math.sqrt(this.velocity.x ** 2 + this.velocity.y ** 2);
            if (speed > maxSpeed) {
                this.velocity.x = (this.velocity.x / speed) * maxSpeed;
                this.velocity.y = (this.velocity.y / speed) * maxSpeed;
            }
            this.position.x += this.velocity.x;
            this.position.y += this.velocity.y;
            if (this.position.x < 0) this.position.x = canvasRef.current.width;
            else if (this.position.x > canvasRef.current.width) this.position.x = 0;
            if (this.position.y < 0) this.position.y = canvasRef.current.height;
            else if (this.position.y > canvasRef.current.height) this.position.y = 0;
            this.acceleration.x = 0;
            this.acceleration.y = 0;
        }

        findClosestBoid(boids) {
            let closestBoid = null;
            let closestDistance = Infinity;
            for (let boid of boids) {
                let distance = Math.hypot(this.position.x - boid.position.x, this.position.y - boid.position.y);
                if (distance < closestDistance) {
                    closestDistance = distance;
                    closestBoid = boid;
                }
            }
            return closestBoid;
        }

        draw(ctx) {
            ctx.beginPath();
            ctx.arc(this.position.x, this.position.y, 1.5, 0, Math.PI * 2);
            ctx.fillStyle = 'red';
            ctx.fill();
        }
    }

    const initializeSimulation = (canvas, ctx) => {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        boids = [];
        for (let i = 0; i < numBoids; i++) {
            boids.push(new Boid(Math.random() * canvas.width, Math.random() * canvas.height));
        }

        adversary = new Adversary(Math.random() * canvas.width, Math.random() * canvas.height);
    };

    const handleResize = (canvas, ctx, prevWidth, prevHeight) => {
        const newWidth = window.innerWidth;
        const newHeight = window.innerHeight;

        // Scale the boids' positions to the new canvas size
        boids.forEach(boid => {
            boid.position.x = (boid.position.x / prevWidth) * newWidth;
            boid.position.y = (boid.position.y / prevHeight) * newHeight;
            boid.target.x = (boid.target.x / prevWidth) * newWidth;
            boid.target.y = (boid.target.y / prevHeight) * newHeight;
        });

        adversary.position.x = (adversary.position.x / prevWidth) * newWidth;
        adversary.position.y = (adversary.position.y / prevHeight) * newHeight;

        canvas.width = newWidth;
        canvas.height = newHeight;
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        initializeSimulation(canvas, ctx);

        const animate = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            boids.forEach(boid => {
                boid.applyBehaviors(boids, adversary);
                boid.update(canvas);
                boid.draw(ctx);
            });
            adversary.update(boids);
            adversary.draw(ctx);
            requestAnimationFrame(animate);
        };

        document.addEventListener('mousemove', (event) => {
            mouse.x = event.clientX;
            mouse.y = event.clientY;
        });

        window.addEventListener('resize', () => {
            const prevWidth = canvas.width;
            const prevHeight = canvas.height;
            handleResize(canvas, ctx, prevWidth, prevHeight);
        });

        animate();

        return () => {
            document.removeEventListener('mousemove', (event) => { });
            window.removeEventListener('resize', (event) => { });
        };
    }, []);

    return <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }}></canvas>;
};

export default BoidSimulation;
