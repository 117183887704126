import React from 'react';
import './App.css';
import BoidSimulation from "./BoidSimulation"; // Adjust the path as needed



function App() {
  return (
    <div className="full-page">
        <BoidSimulation style={{ zIndex: 0 }} />
        <img src="duckboy.png" alt="Bird" className="absolute-position"/> 
        <div className="text">
          <div>IM A</div>
          <div>BIRD</div>
        </div>
    </div>
  );
}

export default App;
